import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Vigo from "../../components/Portfolio/Vigo"

const VigoPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <Vigo />
  </Layout>
)

export default VigoPage
